<template>
  <modal-card :title="'Site secrets'">
    <site-secrets :site-id="siteId" />
  </modal-card>
</template>

<script>
export default {
  name: "SiteSecretsModal",
  components: {
    "site-secrets": () => import("@shared/sites/_siteSecrets")
  },
  props: {
    siteId: {
      type: String,
      required: true
    }
  }
};
</script>
